<template>
  <div class="page">
    
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.openShopUuid"
          placeholder="全部门店"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in bindList"
            :key="item.poiId"
            :label="item.poiName"
            :value="item.poiId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.onlineStatus"
          placeholder="全部状态"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(onlineStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.bindStatus"
          @change="reload"
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(bindStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onRefresh()">同步团购券</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="团购ID" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.productId || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商家名" align="center"  v-if="tableParams.tabType == '1'">
        <template slot-scope="scope">
          <span>{{ scope.row.accountName || "-" }}</span>
        </template>
      </el-table-column>
        <el-table-column label="团购价 / 划线价格" align="center" >
          <template slot-scope="scope">
            <span>￥{{ scope.row.actualAmount || "0" }}</span>
            <span>&nbsp;/&nbsp;</span>
            <s>￥{{ scope.row.originAmount || "0" }}</s>
          </template>
        </el-table-column>
        <el-table-column label="团购开始时间" align="center" >
          <template slot-scope="scope">
            <span>{{ scope.row.soldStartTime || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="团购结束时间" align="center" >
          <template slot-scope="scope">
            <span>{{ scope.row.soldEndTime || "-" }}</span>
          </template>
        </el-table-column>
      <el-table-column label="团购名称" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.productName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" >
        <template slot-scope="scope">
          <span>{{ onlineStatusObj[scope.row.onlineStatus] || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="绑定卡券" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.cardName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">修改绑定卡券</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%">
      <!-- 筛选 -->
      <section class="table-search" style="padding-bottom: .15rem;border-bottom: 1px solid #eee;">
        <div class="search-L">
          <el-select v-model="formCardParams.shopId" placeholder="请选择适用门店" @change="onShop" clearable class="mr15">
            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>
          <el-select v-model="formCardParams.serviceId" placeholder="请选择服务内容" @change="getCardList" clearable
            class="mr15">
            <el-option v-for="item in serviceList" :key="item.serviceId" :label="item.serviceName"
              :value="item.serviceId">
            </el-option>
          </el-select>
          <el-select v-model="formCardParams.cardType" placeholder="请选择卡券类型" @change="getCardList" clearable class="mr15">
            <el-option v-for="item in ObjToOpt(cardTypeObj)" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </section>
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px" v-if="cardList.length">
        <section class="form-main">
          <el-form-item label="卡券列表" prop="couponId" class="long-content">
            <el-radio-group v-model="params.couponId">
              <div class="shop-item" :span="6" v-for="item in cardList" :key="item.cardId">
                <el-radio-button border :label="item.cardId">{{ item.cardName }}</el-radio-button>
              </div>
            </el-radio-group>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
      <el-empty v-else description="暂无卡券，请先添加卡券"></el-empty>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTableList,
  getRefreshList,
  getCardList,
  getCardBind,
  getShopList
} from "@/api/group/card";
import {getDouyinShopList} from '@/api/group/shop.js'

import { rules } from "@/db/rules";
import { ObjToOpt } from "@/utils/utils"
import { onlineStatusObj, cardTypeObj ,bindStatusObj} from "@/db/objs";
import {
  getServiceList,
} from "@/api/resource/room";
export default {
  data() {
    return {
      onlineStatusObj,bindStatusObj,
      cardTypeObj,
      ObjToOpt,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        tabType: "1",
        openShopUuid:'',
        onlineStatus:'',
        bindStatus:0
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      rules,
      shopList: [],
      cardList: [],
      serviceList: [],
      formCardParams: {
        shopId: "",
        serviceId: "",
        cardType: "",
      },
      params: {
        couponId: "",
      },
      bindList:[]
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getTableList();// 【请求】表格数据
    this.getDouyinShopList()
  },
  methods: {
    getDouyinShopList(){
      getDouyinShopList(this.tableParams).then(res=>{
        if (res.isSuccess == "yes") {
          this.bindList = res.data

        }

      })
    },
    // 【请求】表格数据
    getTableList() {
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】修改卡券绑定门店列表
    getShopList(row) {
      let data = {
        goodsId: row.id,
        tuanGouType: this.tableParams.tabType
      }
      getShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.shopList = res.data;
          if (res.data.length) {
            this.formCardParams.shopId = res.data[0].shopId;
            this.getCardList();
            this.getServiceList();
          }
        }
      });
    },

    // 【请求】查询修改绑定卡券列表
    getCardList() {
      let data = this.formCardParams;
      getCardList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardList = res.data;
        }
      });
    },

    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.formCardParams.shopId
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },

    // 【请求】修改绑定卡券
    getCardBind() {
      let data = this.params;
      getCardBind(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.reload();
        }
      });
    },

    // 【请求】表格数据
    getRefreshList() {
      let data = this.tableParams;
      getRefreshList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.reload();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "修改绑定卡券";
      this.formCardParams = {
        shopId: "",
        serviceId: "",
        cardType: "",
      }
      this.shopList = [];
      this.cardList = [];
      this.getShopList(row);
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】同步
    onRefresh() {
      this.getRefreshList()
    },

    // 切换tab
    onTab(tab) {
      console.log('tab: ', tab);
    },

    // 【监听】店铺切换
    onShop() {
      this.formCardParams.serviceId = "";
      this.getCardList();
      this.getServiceList();
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getCardBind();
        } else {
          return false;
        }
      });
    },
  }
}
</script>


<style lang="scss" scoped>
.page {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.el-radio-group {
  width: 100%;
}

.shop-item {
  display: inline-flex;
  margin-right: .1rem;
  margin-bottom: .1rem;
}
</style>